import { Component  } from '@angular/core';
import { ReactiveFormsModule, Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { UserService } from '../../../services/user.service';
import { AlertService } from '../../../services/alert.service';
import { ViacepService } from '../../../services/viacep.service';
import { CityStateService } from '../../../services/city-state.service';
import { RequestorService } from '../../../services/requestor.service';
import { NgxMaskPipe } from 'ngx-mask';
import { catchError, take, throwError, debounceTime } from 'rxjs';
import { FwInputComponent, oabValidator } from '../../shared/fw-input/fw-input.component';
import { FwSelectComponent } from '../../shared/fw-select/fw-select.component';
import moment from 'moment';
import { OfficeService } from '../../../services/office.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { cpfValidator, cnpjValidator } from '../../../utils/document.validator';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
  standalone: true,
  imports: [NgxMaskPipe, ReactiveFormsModule, FwInputComponent, FwSelectComponent]
})
export class OnboardingComponent {
  currentStep = 1;
  timeToResend = 30;
  editPhone = false;
  resendCodeBtn = false;

  dadosForm: FormGroup;
  officeForm: FormGroup;
  userData: any;

  estadosList: any = [];
  cidadesList: any = [];

  personalForm: FormGroup;
  profilePhotoUrl: SafeUrl | null = null;

  cpfBool = new FormControl(true);

  constructor(
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private alertService: AlertService,
    private fb: FormBuilder,
    private viacep: ViacepService,
    private cityState: CityStateService,
    private requestor: RequestorService,
    private office: OfficeService,
  ) {
    this.dadosForm = this.fb.group({
      email: ['', [Validators.required]],
      code: ['', [Validators.required]],
      phone: ['', [Validators.required]],
    });

    this.officeForm = this.fb.group({
      id: [''],
      name: ['', [Validators.required]],
      state: ['', [Validators.required]],
      city: ['', [Validators.required]],
      country: [''],
      address_neighborhood: ['', [Validators.required]],
      address: ['', [Validators.required]],
      address_number: ['', [Validators.required]],
      zip_code: ['', [Validators.required]],
      address_complement: [''],
      cpf: ['', [Validators.required, cpfValidator]],
      cnpj: ['', [cnpjValidator]],
      company_name: ['']
    });

    this.personalForm = this.fb.group({
      id: [''],
      oab_number: ['', [Validators.required, oabValidator]],
      oab_sectional: ['', [Validators.required]],
    });

    // CEP auto-complete
    this.officeForm.get('zip_code')?.valueChanges
      .pipe(debounceTime(400))
      .subscribe((zip_code) => {
        if (zip_code?.length === 8) {
          this.viacep.getAddress(zip_code).subscribe((res: any) => {
            this.officeForm.patchValue({
              country: 'Brasil',
              state: res.uf,
              city: res.localidade,
              address_neighborhood: res.bairro,
              address: res.logradouro
            });
          });
        }
      });

    // City list update when state changes
    this.officeForm.get('state')?.valueChanges.subscribe((state) => {
      if (state) {
        this.cityState.getCidades(state).subscribe((res: any) => {
          this.cidadesList = res.map((cidade: any) => ({
            label: cidade,
            value: cidade
          }));
        });
      }
    });
  }

  ngOnInit(): void {
    this.authService.userData$.subscribe(data => {
      this.userData = data;
      this.dadosForm.patchValue(this.userData);
      this.personalForm.patchValue(this.userData);
    });
    this.timeToResend = 30;
    this.getTimeToResend();

    this.cityState.getEstados().subscribe((res: any) => {
      this.estadosList = res.map((estado: any) => ({
        label: estado.name,
        value: estado.uf
      }));
    });

    this.getOffice();
  }

  getOffice() {
    this.office.get().subscribe((res: any) => {
      this.officeForm.patchValue(res[0]);
    });
  }

  resendCode() {
    this.userService.resendCode(this.dadosForm.value).subscribe(() => {
      this.resendCodeBtn = true;
      this.timeToResend = 30;
      this.getTimeToResend();
    });
  }

  verifyCode() {
    if (this.dadosForm.get('code')?.valid) {
      this.userService.checkCode(this.dadosForm.value)
        .pipe(
          take(1),
          catchError(err => {
            this.showWarningAlert();
            return throwError(() => err);
          })
        )
        .subscribe({
          next: () => {
            this.currentStep = 2;
            this.authService.getUser();
          },
          error: () => {
            this.showWarningAlert();
            this.dadosForm.get('code')?.reset();
          }
        });
    }
  }

  private showWarningAlert() {
    this.alertService.showAlert({
      message: 'Código inválido. Por favor, verifique o código e tente novamente.',
      type: 'warning',
      closeTimeout: 5000,
      confirmText: 'Entendi'
    }, { width: '400px' });
  }

  getTimeToResend() {
    const interval = setInterval(() => {
      this.timeToResend--;
      if (this.timeToResend <= 0) {
        clearInterval(interval);
      }
    }, 1000);
  }

  get formattedTimeToResend(): string {
    if (this.timeToResend <= 0) return 'Reenviar Código';
    return moment.utc(this.timeToResend * 1000).format('mm:ss');
  }

  get allowResendCode(): boolean {
    return this.timeToResend <= 0;
  }

  editPhoneNumber() {
    this.editPhone = true;
  }

  savePhone() {
    if (this.dadosForm.get('phone')?.valid) {
      this.editPhone = false;
      this.resendCode();
    }
  }

  onOABSubmit(): void {
    if (this.personalForm.valid && this.officeForm.get('cpf')?.valid) {
      this.userService.patchDadosMinhaConta(this.personalForm.value.id, this.personalForm.value).subscribe({
        next: () => {
          this.currentStep = 3;
          this.authService.getUser();
        },
      });
    } else {
      this.personalForm.markAllAsTouched();
    }
  }

  onOfficeSubmit(): void {
    if (this.officeForm.valid) {
      this.requestor.patchOffice(this.officeForm.get('id')?.value, this.officeForm.value).subscribe({
        next: () => {
          this.alertService.showAlert({
            message: 'Cadastro concluído com sucesso!',
            type: 'success',
            closeTimeout: 5000,
            confirmText: 'OK'
          }, { width: '400px' });
          this.router.navigate(['/contratante/inicio']);
        },
        error: (erro) => {
          this.alertService.showAlert({
            message: 'Erro ao salvar dados do escritório. Tente novamente.',
            type: 'error',
            closeTimeout: 5000,
            confirmText: 'Entendi'
          }, { width: '400px' });
        }
      });
    } else {
      this.officeForm.markAllAsTouched();
    }
  }

  get isCPF(): boolean {
    return this.officeForm.get('cpf')?.value;
  }

}
