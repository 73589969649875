<!-- Estrutura HTML usando Angular 18 -->
<div class="onboarding-wrapper">
  <div class="content-container">
    <!-- Seção Principal - Conteúdo dos Steps -->
    <div class="w-100 d-flex justify-content-center">
      <div class="wgt-card p-4 p-lg-5">
        @switch (currentStep) {
          @case (1) {
            <div class="d-flex align-items-center gap-3 mb-4">
              <span class="content-icon material-symbols-rounded">phone</span>
              <div>
                <h4>Verificação do Telefone</h4>
                <p class="step-description mb-0">Vamos garantir a segurança da sua conta.</p>
              </div>
            </div>

            <p class="mb-4">Enviamos um SMS com um código de verificação para o seu número. <br>Caso não tenha recebido, verifique se o número de telefone informado está correto e tente novamente.</p>
            <div class="d-flex align-items-center gap-3">
              <div class="number-tag">
                <span class="material-symbols-rounded ms-1">phone</span> {{ userData.phone | mask: '(00) 00000-0000' }}
                <button class="btn-icon" (click)="editPhoneNumber()"><span class="material-symbols-rounded">edit</span></button>
              </div>
              <div class="resend-btn" (click)="resendCode()" [class.disabled]="!allowResendCode">
                <span class="material-symbols-rounded">refresh</span>
                <span class="resend-text">{{ formattedTimeToResend }}</span>
              </div>
            </div>
            @if (!editPhone) {
              <fw-input [showRequired]="false" [fControl]="dadosForm.get('code')" class="code-input mb-4" [placeholder]="'------'" mask="000000"></fw-input>
              <button class="fw-btn btn-first w-100" (click)="verifyCode()">Verificar Código</button>
            } @else {
              <fw-input [showRequired]="false" [fControl]="dadosForm.get('phone')" class="mb-4" [placeholder]="'Informe o número de telefone'" mask="(00) 00000-0000"></fw-input>
              <button class="fw-btn btn-first w-100" (click)="savePhone()">Alterar e reenviar código</button>
            }

          }
          @case (2) {
            <div class="d-flex align-items-center gap-3 mb-4">
              <span class="content-icon material-symbols-rounded">gavel</span>
              <div>
                <h4>Dados pessoais</h4>
                <p class="step-description mb-0">Precisamos dos dados do responsável pelo escritório</p>
              </div>
            </div>

            <form [formGroup]="personalForm" (ngSubmit)="onOABSubmit()" class="form-group">
              <div class="row g-4">
                <div class="col-12">
                  <fw-input [fControl]="officeForm.get('cpf')"
                    label="CPF"
                    placeholder="000.000.000-00"
                    mask="000.000.000-00">
                  </fw-input>
                </div>
                <div class="col-md-7">
                  <fw-input [fControl]="personalForm.get('oab_number')"
                    label="OAB"
                    placeholder="Digite o número da OAB"
                    mask="000999">
                  </fw-input>
                </div>
                <div class="col-md-5">
                  <fw-select [fControl]="personalForm.get('oab_sectional')"
                    label="Seccional"
                    [options]="estadosList">
                  </fw-select>
                </div>
              </div>

              <button class="fw-btn btn-first w-100 d-flex align-items-center justify-content-center gap-2">
                <span>Próximo</span>
                <span class="material-symbols-rounded">arrow_forward</span>
              </button>
            </form>
          }
          @case (3) {
            <div class="d-flex align-items-center gap-3 mb-4">
              <span class="content-icon material-symbols-rounded">business</span>
              <div>
                <h4>Dados do Escritório</h4>
                <p class="step-description mb-0">Complete as informações do seu escritório.</p>
              </div>
            </div>

            <form [formGroup]="officeForm" (ngSubmit)="onOfficeSubmit()">
              <!-- Basic Info -->
              <div class="section-content mb-4">
                <div class="row">
                  <div class="col-8">
                    <fw-input [fControl]="officeForm.get('name')"
                      label="Nome do Escritório"
                      placeholder="Nome do Escritório">
                    </fw-input>
                  </div>
                  <div class="col-md-4">
                    <fw-select [fControl]="cpfBool"
                      label="Pessoa"
                      [options]="[
                        { label: 'Jurídica', value: false },
                        { label: 'Física', value: true }
                      ]">
                    </fw-select>
                  </div>
                  @if (cpfBool.value) {
                    <div class="col-md-12">
                      <fw-input [fControl]="officeForm.get('cpf')"
                        label="CPF"
                      placeholder="000.000.000-00"
                        mask="000.000.000-00">
                      </fw-input>
                    </div>
                  } @else {
                    <div class="col-md-5">
                      <fw-input [fControl]="officeForm.get('cnpj')"
                      label="CNPJ"
                      placeholder="00.000.000/0000-00"
                      mask="00.000.000/0000-00">
                      </fw-input>
                    </div>
                    <div class="col-md-7">
                      <fw-input [fControl]="officeForm.get('company_name')"
                        label="Razão Social"
                      placeholder="Razão Social">
                      </fw-input>
                    </div>
                  }
                </div>
              </div>

              <!-- Address -->
              <div class="section-content mb-4">
                <div class="row">
                  <div class="col-md-3">
                    <fw-input [fControl]="officeForm.get('zip_code')"
                      label="CEP"
                      placeholder="00000-000"
                      mask="00000-000">
                    </fw-input>
                  </div>
                  <div class="col-md-6">
                    <fw-input [fControl]="officeForm.get('address')"
                      label="Logradouro"
                      placeholder="Logradouro">
                    </fw-input>
                  </div>
                  <div class="col-md-3">
                    <fw-input [fControl]="officeForm.get('address_number')"
                      label="Número"
                      placeholder="Número">
                    </fw-input>
                  </div>
                  <div class="col-md-5">
                    <fw-input [fControl]="officeForm.get('address_neighborhood')"
                      label="Bairro"
                      placeholder="Bairro">
                    </fw-input>
                  </div>
                  <div class="col-md-7">
                    <fw-input [fControl]="officeForm.get('address_complement')"
                      label="Complemento"
                      placeholder="Complemento">
                    </fw-input>
                  </div>
                  <div class="col-md-6">
                    <fw-select [fControl]="officeForm.get('state')"
                      label="Estado"
                      [options]="estadosList">
                    </fw-select>
                  </div>
                  <div class="col-md-6">
                    <fw-select [fControl]="officeForm.get('city')"
                      label="Cidade"
                      [options]="cidadesList">
                    </fw-select>
                  </div>
                </div>
              </div>

              <button type="submit" class="fw-btn btn-first w-100">Concluir</button>
            </form>
          }
        }
      </div>

    </div>

    <!-- Steps laterais com animações suaves -->
    <div class="w-auto d-none d-lg-block steps-container">
      <div class="steps-list">
        <button
          class="step-button"
          [class.active]="currentStep === 1"
          [class.completed]="currentStep > 1">
          <span class="step-icon material-symbols-rounded">phone</span>
          <div class="step-info">
            <span class="step-title">Verificar Telefone</span>
            <span class="step-subtitle">Validação em 2 etapas</span>
          </div>
        </button>

        <button
          class="step-button"
          [class.active]="currentStep === 2"
          [class.completed]="currentStep > 2"
          [class.disabled]="currentStep < 2">
          <span class="step-icon material-symbols-rounded">gavel</span>
          <div class="step-info">
            <span class="step-title">Dados Pessoais</span>
            <span class="step-subtitle">Informações do responsável</span>
          </div>
        </button>

        <button
          class="step-button"
          [class.active]="currentStep === 3"
          [class.completed]="currentStep > 3"
          [class.disabled]="currentStep < 3">
          <span class="step-icon material-symbols-rounded">business</span>
          <div class="step-info">
            <span class="step-title">Dados do Escritório</span>
            <span class="step-subtitle">Informações do escritório</span>
          </div>
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Success Dialog -->
<ng-template #successDialog>
  <div class="success-dialog">
    <h4>Parabéns!</h4>
    <p>Seu cadastro foi concluído com sucesso! Agora você pode delegar serviços na plataforma.</p>
    <div class="dialog-actions">
      <button class="btn-second" (click)="onPostponeDelegation()">Deixar para depois</button>
      <button class="btn-first" (click)="onStartDelegation()">Vamos lá</button>
    </div>
  </div>
</ng-template>
